import React, { useState, useMemo } from "react";
import WeatherWidget from "./components/WeatherWidget";
import "./styles.css";
import { EntityWeather } from "./entity";
import { DME } from "dmeditor";

const Weather = (props: DME.WidgetRenderProps<EntityWeather>) => {
  const { city = "Oslo, Norway" } = props.blockNode.data;

  const cityArr = useMemo(() => {
    const arr = city.split(",");
    if (arr.length === 2) {
      return [arr[0].trim(), arr[1].trim()];
    }
    return null;
  }, [city]);

  return (
    <div>
      {cityArr && <WeatherWidget city={cityArr[0]} country={cityArr[1]} />}
      {!cityArr && (
        <div style={{ color: "white", padding: 5, fontStyle: "italic" }}>
          Error: need input city, Country
        </div>
      )}
    </div>
  );
};

export { Weather };
