import React, { useContext } from "react";
import useFetch from "../hooks/useFetch";
import ForecastCards from "./ForecastCards";
import styled from "@emotion/styled";
import WeatherToday from "./WeatherToday";

const LoaderWrapper = styled.div`
  margin-top: 5rem;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavCity = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;

  button {
    border: 1px solid #dbe7fc;
    background: transparent;
    padding: 15px 25px;
    border-radius: 50px;
    color: #dbe7fc;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const WeatherWidget = (props: { city: string; country: string }) => {
  const { data, list, loading } = useFetch(
    `https://api.openweathermap.org/data/2.5/forecast?q=${props.city},${props.country}&units=metric`
  );

  return (
    <>
      {loading ? (
        <LoaderWrapper>Loading...</LoaderWrapper>
      ) : (
        <>
          <NavWrapper>
            <NavCity>{props.city}</NavCity>
          </NavWrapper>

          {/* <WeatherToday /> */}

          <ForecastCards list={list} />
        </>
      )}
    </>
  );
};

export default WeatherWidget;
