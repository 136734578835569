import { weatherWidgetDef } from "./definition";
import { Weather } from "./Weather";
import { registerSettingComponent, registerWidget } from "dmeditor";

const registerWeatherWidget = function () {
  registerWidget(weatherWidgetDef, {
    render: Weather,
  });
};

export default registerWeatherWidget;
