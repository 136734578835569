import "./styles.css";
import { registerDefaultWidgets, DMEditor } from "dmeditor";
import { Editor } from "./Editor";
import { dmeInit } from "./dmeInit";

dmeInit();

export default function App() {
  return (
    <div className="App">
      <Editor />
    </div>
  );
}
