import { DMEData, DMEditor, DMEditorRefType } from "dmeditor";
import { useEffect, useRef } from "react";

export const Editor = () => {
  const editorRef = useRef<DMEditorRefType>(null);

  useEffect(() => {
    if (editorRef && editorRef.current) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    fetch("/data.json")
      .then((res) => res.json())
      .then((data) => {
        editorRef?.current?.setData(data as any);
      });
  };

  const save = (data: DMEData.SavedData) => {
    window.alert("Save triggered. Open console to see data.");
    console.log(data);
  };

  const cancel = () => {
    window.alert("Canceled");
  };

  return <DMEditor ref={editorRef} onSave={save} onCancel={cancel} />;
};
